import React, { useState } from 'react';
import axios from 'axios';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    reasonForEnquiry: '',
    email: '',
    phone: '',
    designation: '',
    company: '',
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.firstName === "" ||
      formData.lastName === "" ||
      formData.reasonForEnquiry === "" ||
      formData.designation === "" ||
      formData.company === "" ||
      formData.email === "" ||
      formData.phone === "" 
    ) {
      alert("All fields are required");
    } else {
      try {
        const response = await axios.post("http://localhost:8080/api/contact", formData);
        if (response.status === 200) {
          setMessage("Form submitted successfully!");
          console.log("Form data sent successfully:", response.data);
          
          // Clear the form
          setFormData({
            firstName: '',
            lastName: '',
            reasonForEnquiry: '',
            email: '',
            phone: '',
            designation: '',
            company: '',
          });

          // Refresh page after 1.5 seconds
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          setMessage("Something went wrong. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setMessage("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="container mx-auto px-4 mt-20 sm:mt-0 pt-8 sm:pt-24 font-helvetica">
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-black mb-3 sm:mb-2 px-3 sm:px-0 leading-tight sm:leading-normal">
        JOIN THE REVOLUTION IN SECURE ENTERPRISE SOLUTIONS
      </h1>
      <p className="text-center text-gray-600 mb-6 sm:mb-8 text-sm sm:text-base px-4 sm:px-0 leading-relaxed">
        Let's scale your business together. Fill out the form and we will get back to you.
      </p>
      
      <form onSubmit={handleSubmit} className="max-w-3xl mx-auto px-2 sm:px-0">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 sm:gap-6">
          <div className="space-y-1 sm:space-y-0">
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-0">
              First Name *
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 text-base sm:text-sm p-2.5 sm:p-2"
              onChange={handleChange}
              value={formData.firstName}
            />
          </div>

          <div className="space-y-1 sm:space-y-0">
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-0">
              Last Name *
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 text-base sm:text-sm p-2.5 sm:p-2"
              onChange={handleChange}
              value={formData.lastName}
            />
          </div>

          <div className="space-y-1 sm:space-y-0">
            <label htmlFor="reasonForEnquiry" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-0">
              Reason for Enquiry *
            </label>
            <select
              id="reasonForEnquiry"
              name="reasonForEnquiry"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 text-base sm:text-sm p-2.5 sm:p-2"
              onChange={handleChange}
              value={formData.reasonForEnquiry}
            >
              <option value="">Select a reason</option>
              <option value="general">General Inquiry</option>
              <option value="support">Technical Support</option>
              <option value="sales">Sales Information</option>
            </select>
          </div>

          <div className="space-y-1 sm:space-y-0">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-0">
              Email *
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 text-base sm:text-sm p-2.5 sm:p-2"
              onChange={handleChange}
              value={formData.email}
            />
          </div>

          <div className="space-y-1 sm:space-y-0">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-0">
              Phone *
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm sm:text-xs">
                +91
              </span>
              <input
                type="tel"
                id="phone"
                name="phone"
                required
                className="flex-1 block w-full rounded-none rounded-r-md border-gray-300 focus:border-green-500 focus:ring-green-500 text-base sm:text-sm p-2.5 sm:p-2"
                onChange={handleChange}
                value={formData.phone}
              />
            </div>
          </div>

          <div className="space-y-1 sm:space-y-0">
            <label htmlFor="designation" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-0">
              Designation *
            </label>
            <input
              type="text"
              id="designation"
              name="designation"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 text-base sm:text-sm p-2.5 sm:p-2"
              onChange={handleChange}
              value={formData.designation}
            />
          </div>
        </div>

        <div className="mt-6 space-y-1 sm:space-y-0">
          <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-0">
            Company *
          </label>
          <input
            type="text"
            id="company"
            name="company"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 text-base sm:text-sm p-2.5 sm:p-2"
            onChange={handleChange}
            value={formData.company}
          />
        </div>

        <div className="mt-8">
          <button
            type="submit"
            className="w-full flex justify-center py-3 sm:py-2 px-4 border border-transparent rounded-md shadow-sm text-base sm:text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Submit
          </button>
        </div>
      </form>
      
      {message && (
        <p className="mt-4 text-center text-green-900 text-sm sm:text-base">
          {message}
        </p>
      )}
    </div>
  );
};

export default ContactUs;