import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logo3.jpg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);
  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const timeoutRef = useRef(null);
  const aboutUsTimeoutRef = useRef(null);
  const solutionsTimeoutRef = useRef(null);
  const location = useLocation();

  const toggleDropdown = (setter) => {
    setter(prev => !prev);
  };

  const handleMouseEnter = (setter) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (aboutUsTimeoutRef.current) clearTimeout(aboutUsTimeoutRef.current);
    if (solutionsTimeoutRef.current) clearTimeout(solutionsTimeoutRef.current);
    setter(true);
  };

  const handleMouseLeave = () => {
    const isAboutUsOpenNow = isAboutUsOpen;
    const isSolutionsOpenNow = isSolutionsOpen;

    if (isAboutUsOpenNow && isSolutionsOpenNow) {
      aboutUsTimeoutRef.current = setTimeout(() => {
        setIsAboutUsOpen(false);
      }, 200);

      solutionsTimeoutRef.current = setTimeout(() => {
        setIsSolutionsOpen(false);
      }, 300);
    } else {
      timeoutRef.current = setTimeout(() => {
        setIsAboutUsOpen(false);
        setIsSolutionsOpen(false);
      }, 300);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (aboutUsTimeoutRef.current) clearTimeout(aboutUsTimeoutRef.current);
      if (solutionsTimeoutRef.current) clearTimeout(solutionsTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const isHomePage = location.pathname === '/';
  const navbarClass = isScrolled || !isHomePage
    ? "fixed top-0 left-0 right-0 z-40 bg-[#faf9f6] shadow-md transition-all duration-300"
    : "fixed top-0 left-0 right-0 z-40 bg-gradient-to-b from-black/50 to-transparent transition-all duration-300";

  const linkClass = isScrolled || !isHomePage
    ? "text-gray-800 hover:bg-gray-200"
    : "text-white hover:bg-gray-800";
    return (
      <nav className={navbarClass}>
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-20">
            {/* Logo */}
            <div className="flex-shrink-0 flex items-center md:static absolute left-1/2 -translate-x-1/2 md:translate-x-0 md:left-0">
              <Link to="/" className="flex items-center">
                <img src={logo} alt="Robnett Logo" className="h-16 w-auto" />
              </Link>
            </div>
  
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-4">
              <Link to="/" className={`${linkClass} px-3 py-2 rounded-md text-sm font-medium transition duration-300`}>
                Home
              </Link>
  
              {/* About Us Dropdown */}
              <div className="relative" onMouseEnter={() => handleMouseEnter(setIsAboutUsOpen)} onMouseLeave={handleMouseLeave}>
                <button className={`${linkClass} px-3 py-2 rounded-md text-sm font-medium transition duration-300 flex items-center`}>
                  About Us
                  <svg className="ml-1 h-4 w-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
  
                {isAboutUsOpen && (
                  <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Link to="/about-us/our-mission" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Our Mission
                      </Link>
                      <Link to="/about-us/founders-message" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Founder's Message
                      </Link>
                      <Link to="/blog" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Blog
                      </Link>
                    </div>
                  </div>
                )}
              </div>
  
              {/* Solutions Dropdown */}
              <div className="relative" onMouseEnter={() => handleMouseEnter(setIsSolutionsOpen)} onMouseLeave={handleMouseLeave}>
                <button className={`${linkClass} px-3 py-2 rounded-md text-sm font-medium transition duration-300 flex items-center`}>
                  Solutions
                  <svg className="ml-1 h-4 w-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
  
                {isSolutionsOpen && (
                  <div className="absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Link to="/solutions/managed-server" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Managed Server
                      </Link>
                      <Link to="/solutions/cloud-solutions" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Cloud Solutions
                      </Link>
                      <Link to="/solutions/tally-solutions" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Tally Solutions
                      </Link>
                      <Link to="/solutions/backup-services" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Backup Services
                      </Link>
                      <Link to="/solutions/web-services" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Web Services
                      </Link>
                    </div>
                  </div>
                )}
              </div>
  
              <Link to="/contact-us" className="bg-blue-500 text-white hover:bg-blue-600 px-4 py-2 rounded-md text-sm font-medium transition duration-300">
                Contact Us
              </Link>
            </div>
  
            {/* Mobile Menu Button */}
            <div className="md:hidden flex items-center">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className={`${isScrolled || !isHomePage ? 'text-gray-800' : 'text-white'} p-2 rounded-md hover:bg-gray-100 hover:bg-opacity-20 focus:outline-none`}
              >
                <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
          </div>
  
          {/* Mobile Menu Overlay */}
          {isOpen && (
            <div className="fixed inset-0 bg-white z-50 md:hidden overflow-y-auto">
              <div className="flex flex-col items-center border-b pb-4">
                {/* Close button */}
                <button 
                  onClick={() => setIsOpen(false)}
                  className="absolute right-4 top-4 p-2 text-gray-800 hover:bg-gray-100 rounded-full transition-colors duration-200"
                >
                  <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                
                {/* Logo with Link */}
                <div className="mt-4 mb-4">
                  <Link to="/" onClick={() => setIsOpen(false)}>
                    <img src={logo} alt="Robnett Logo" className="h-16 w-auto" />
                  </Link>
                </div>
              </div>
  
              <div className="px-4 py-2">
                {/* About Us Section */}
                <div className="border-b">
                  <button 
                    onClick={() => setIsAboutUsOpen(!isAboutUsOpen)}
                    className="w-full py-4 flex justify-between items-center text-gray-800 hover:bg-gray-100 transition-colors duration-200"
                  >
                    About Us
                    <svg 
                      className={`w-4 h-4 transform transition-transform ${isAboutUsOpen ? 'rotate-180' : ''}`} 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  {isAboutUsOpen && (
                    <div className="pl-4 pb-4">
                      <Link to="/about-us/our-mission" className="block py-2 text-gray-600 hover:bg-gray-100 transition-colors duration-200" onClick={() => setIsOpen(false)}>
                        Our Mission
                      </Link>
                      <Link to="/about-us/founders-message" className="block py-2 text-gray-600 hover:bg-gray-100 transition-colors duration-200" onClick={() => setIsOpen(false)}>
                        Founder's Message
                      </Link>
                      <Link to="/blog" className="block py-2 text-gray-600 hover:bg-gray-100 transition-colors duration-200" onClick={() => setIsOpen(false)}>
                        Blog
                      </Link>
                    </div>
                  )}
                </div>
  
                {/* Solutions Section */}
                <div className="border-b">
                  <button 
                    onClick={() => setIsSolutionsOpen(!isSolutionsOpen)}
                    className="w-full py-4 flex justify-between items-center text-gray-800 hover:bg-gray-100 transition-colors duration-200"
                  >
                    Solutions
                    <svg 
                      className={`w-4 h-4 transform transition-transform ${isSolutionsOpen ? 'rotate-180' : ''}`} 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  {isSolutionsOpen && (
                    <div className="pl-4 pb-4">
                      <Link to="/solutions/managed-server" className="block py-2 text-gray-600 hover:bg-gray-100 transition-colors duration-200" onClick={() => setIsOpen(false)}>
                        Managed Server
                      </Link>
                      <Link to="/solutions/cloud-solutions" className="block py-2 text-gray-600 hover:bg-gray-100 transition-colors duration-200" onClick={() => setIsOpen(false)}>
                        Cloud Solutions
                      </Link>
                      <Link to="/solutions/tally-solutions" className="block py-2 text-gray-600 hover:bg-gray-100 transition-colors duration-200" onClick={() => setIsOpen(false)}>
                        Tally Solutions
                      </Link>
                      <Link to="/solutions/backup-services" className="block py-2 text-gray-600 hover:bg-gray-100 transition-colors duration-200" onClick={() => setIsOpen(false)}>
                        Backup Services
                      </Link>
                      <Link to="/solutions/web-services" className="block py-2 text-gray-600 hover:bg-gray-100 transition-colors duration-200" onClick={() => setIsOpen(false)}>
                        Web Services
                      </Link>
                    </div>
                  )}
                </div>
  
                {/* Regular Menu Items */}
                <Link to="/contact-us" className="block py-4 text-gray-800 border-b hover:bg-gray-100 transition-colors duration-200" onClick={() => setIsOpen(false)}>
                  Contact Us
                </Link>
              </div>
            </div>
          )}
        </div>
      </nav>
    );
  };
  
  export default Navbar;