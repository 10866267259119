import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import hyperscaleImage from '../assets/hyperscale.png';
import colocationImage from '../assets/private-cloud.png';
import privateCloudImage from '../assets/tally-home.jpg';
import connectivityImage from '../assets/connectivity.png';
import developmentImage from '../assets/Web.jpg';

gsap.registerPlugin(ScrollTrigger);

const ServiceCard = ({ title, image, description, learnMoreLink }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate(learnMoreLink);
  };

  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-lg mx-2 mb-4 md:mb-0 flex flex-col">
      <div className="relative h-36 md:h-48">
        <img src={image} alt={title} className="w-full h-full object-cover" />
        <h3 className="absolute bottom-0 left-0 right-0 text-white text-xl md:text-2xl font-bold p-3 md:p-4 bg-gradient-to-t from-black/60 to-transparent">
          {title}
        </h3>
      </div>
      <div className="p-3 md:p-4 flex-grow">
        <p className="text-sm md:text-base text-gray-700 mb-3 md:mb-4">{description}</p>
        <Link 
          to={learnMoreLink} 
          onClick={handleClick}
          className="text-green-700 text-sm md:text-base font-semibold hover:underline"
        >
          LEARN MORE →
        </Link>
      </div>
    </div>
  );
};

const Service = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollContainerRef = useRef(null);

  const services = [
    {
      title: "MANAGED SERVER",
      image: hyperscaleImage,
      description: "End-to-end tailored infrastructure that enables hyperscale growth with unmatched performance, security and limitless scalability.",
      learnMoreLink: "/solutions/managed-server"
    },
    {
      title: "CLOUD SOLUTIONS",
      image: colocationImage,
      description: "A highly reliable environment where enterprises' digital infrastructure can operate the best, maximising yields from their investments.",
      learnMoreLink: "/solutions/cloud-solutions"
    },
    {
      title: "TALLY SERVICES",
      image: privateCloudImage,
      description: "Full-stack private cloud and managed services designed for every digital element and application, delivered by our certified domain experts.",
      learnMoreLink: "/solutions/tally-solutions"
    },
    {
      title: "BACKUP SOLUTIONS",
      image: connectivityImage,
      description: "Simple, streamlined and reliable network and connectivity solutions for a seamlessly-connected digital footprint.",
      learnMoreLink: "/solutions/backup-services"
    },
    {
      title: "WEB DEVELOPMENT",
      image: developmentImage,
      description: "Comprehensive web development services tailored to your needs, from responsive design to full-stack applications and beyond.",
      learnMoreLink: "/solutions/web-services"
    }
  ];

  const sectionRef = useRef(null);
  const cardsRef = useRef([]);

  const handleScroll = (direction) => {
    const container = scrollContainerRef.current;
    const scrollAmount = 300;
    
    if (container) {
      const newPosition = direction === 'left' 
        ? Math.max(0, scrollPosition - scrollAmount)
        : Math.min(container.scrollWidth - container.clientWidth, scrollPosition + scrollAmount);
      
      container.scrollTo({
        left: newPosition,
        behavior: 'smooth'
      });
      
      setScrollPosition(newPosition);
    }
  };

  useEffect(() => {
    const ctx = gsap.context(() => {
      const mobileAnimation = gsap.matchMedia();
      
      mobileAnimation.add("(max-width: 768px)", () => {
        cardsRef.current.forEach((card, index) => {
          gsap.set(card, { y: 50, opacity: 0 });
          
          ScrollTrigger.create({
            trigger: card,
            start: "top 85%",
            onEnter: () => {
              gsap.to(card, {
                y: 0,
                opacity: 1,
                duration: 0.6,
                ease: "power3.out"
              });
            },
            once: true
          });
        });
      });

      mobileAnimation.add("(min-width: 769px)", () => {
        const cards = cardsRef.current;
        gsap.set(cards, { y: 100, opacity: 0 });

        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: "top 80%",
          onEnter: () => {
            gsap.to(cards, {
              y: 0,
              opacity: 1,
              duration: 1,
              stagger: 0.2,
              ease: "power3.out"
            });
          },
          once: true
        });
      });
    }, sectionRef);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={sectionRef} className="bg-[#faf9f6] py-8 md:py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl md:text-4xl font-bold text-[#00000] mb-2 text-center md:text-left">
          YOUR PARTNER FOR SECURE DIGITAL TRANSFORMATION
        </h2>
        <p className="text-lg md:text-xl text-gray-600 mb-8 md:mb-12 text-center md:text-left">
          Customized solutions to drive your digital transformation securely and efficiently.
        </p>
        
        {/* Mobile View - Vertical Stack */}
        <div className="block md:hidden">
          {services.map((service, index) => (
            <div 
              key={index} 
              ref={el => cardsRef.current[index] = el}
              className="mb-6"
            >
              <ServiceCard {...service} />
            </div>
          ))}
        </div>

        {/* Desktop View - Scrollable Container with Navigation Buttons */}
        <div className="hidden md:block relative group">
          <button 
            onClick={() => handleScroll('left')}
            className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 hover:bg-white text-gray-800 p-2 rounded-full shadow-lg transition-all duration-300 opacity-0 group-hover:opacity-100 -left-4"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          
          <div 
            ref={scrollContainerRef}
            className="overflow-x-auto scrollbar-hide"
          >
            <div className="flex gap-4 py-2">
              {services.map((service, index) => (
                <div 
                  key={index}
                  ref={el => cardsRef.current[index] = el}
                  className="w-[300px] flex-shrink-0"
                >
                  <ServiceCard {...service} />
                </div>
              ))}
            </div>
          </div>

          <button 
            onClick={() => handleScroll('right')}
            className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 hover:bg-white text-gray-800 p-2 rounded-full shadow-lg transition-all duration-300 opacity-0 group-hover:opacity-100 -right-4"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Service;